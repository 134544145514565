var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "800" } },
    [
      _c(
        "v-card",
        { staticClass: "fill" },
        [
          _c(
            "v-toolbar",
            { staticClass: "main", attrs: { flat: "", dark: "" } },
            [
              _c("v-toolbar-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.readonly ? "Batch Details" : "New Batch Billing Run"
                    ) +
                    " "
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 px-4", attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                { ref: "form", attrs: { "lazy-validation": "" } },
                [
                  _c("v-card-title", { staticClass: "pl-0 pt-0" }, [
                    _vm._v("Details"),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _vm.isReversalOrRebill
                        ? _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.batches,
                                  "item-text": "name",
                                  "return-object": "",
                                  "background-color": "input",
                                  label: "Batch to Reverse/Rebill*",
                                  required: "",
                                  loading: _vm.loading,
                                  "menu-props": { maxHeight: 215 },
                                  rules: [
                                    function (v) {
                                      return (
                                        !!v ||
                                        "Reversal/Rebill Batch is required"
                                      )
                                    },
                                  ],
                                  outlined: "",
                                  dense: "",
                                },
                                on: { change: _vm.reverseInfo },
                                model: {
                                  value: _vm.targetBatch,
                                  callback: function ($$v) {
                                    _vm.targetBatch = $$v
                                  },
                                  expression: "targetBatch",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { xl: "7" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Name*",
                              "background-color": "input",
                              required: "",
                              rules: [
                                function (v) {
                                  return !!v || "Name is required"
                                },
                                _vm.validateText,
                              ],
                              outlined: "",
                              disabled: _vm.readonly,
                              dense: "",
                            },
                            model: {
                              value: _vm.batch.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.batch, "name", $$v)
                              },
                              expression: "batch.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Responsible Party*",
                              items: _vm.responsiblePartyOptions,
                              "background-color": "input",
                              "menu-props": { offsetY: true },
                              "item-text": "name",
                              "item-value": "id",
                              rules: [
                                function (v) {
                                  return !!v || "Responsible party is required"
                                },
                              ],
                              outlined: "",
                              disabled: _vm.readonly,
                              dense: "",
                            },
                            model: {
                              value: _vm.batch.responsible_party_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.batch, "responsible_party_id", $$v)
                              },
                              expression: "batch.responsible_party_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Batch Type*",
                              "background-color": "input",
                              items: _vm.batchTypes,
                              "item-text": "kv_name",
                              "item-value": "kv_constant",
                              "menu-props": { offsetY: true },
                              required: "",
                              rules: [
                                function (v) {
                                  return !!v || "Batch type is required"
                                },
                              ],
                              outlined: "",
                              disabled: _vm.readonly,
                              dense: "",
                            },
                            on: { change: _vm.batchTypeChanged },
                            model: {
                              value: _vm.batch.batch_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.batch, "batch_type", $$v)
                              },
                              expression: "batch.batch_type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scale-transition",
                                "offset-y": "",
                                "nudge-top": "25",
                                "max-width": "290px",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              ref: "start_date",
                                              attrs: {
                                                label: "Start Date",
                                                type: "date",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                dense: "",
                                                "background-color": "input",
                                                outlined: "",
                                                disabled:
                                                  _vm.isReversalOrRebill ||
                                                  _vm.readonly,
                                                rules: [_vm.isValidStartDate],
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.batch.end_date
                                                    ? _vm.$refs.end_date.validate()
                                                    : null
                                                },
                                              },
                                              model: {
                                                value: _vm.batch.start_date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.batch,
                                                    "start_date",
                                                    $$v
                                                  )
                                                },
                                                expression: "batch.start_date",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          !_vm.isReversalOrRebill ? on : null
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.start_dt_picker,
                                callback: function ($$v) {
                                  _vm.start_dt_picker = $$v
                                },
                                expression: "start_dt_picker",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  max: _vm.batch.end_date,
                                  "no-title": "",
                                },
                                model: {
                                  value: _vm.batch.start_date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.batch, "start_date", $$v)
                                  },
                                  expression: "batch.start_date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scale-transition",
                                "offset-y": "",
                                "nudge-top": "25",
                                "max-width": "290px",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              ref: "end_date",
                                              attrs: {
                                                label: "End Date",
                                                type: "date",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                dense: "",
                                                "background-color": "input",
                                                outlined: "",
                                                disabled:
                                                  _vm.isReversalOrRebill ||
                                                  _vm.readonly,
                                                rules: [_vm.isValidEndDate],
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.batch.start_date
                                                    ? _vm.$refs.start_date.validate()
                                                    : null
                                                },
                                              },
                                              model: {
                                                value: _vm.batch.end_date,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.batch,
                                                    "end_date",
                                                    $$v
                                                  )
                                                },
                                                expression: "batch.end_date",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          !_vm.isReversalOrRebill ? on : null
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.end_dt_picker,
                                callback: function ($$v) {
                                  _vm.end_dt_picker = $$v
                                },
                                expression: "end_dt_picker",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  min: _vm.batch.start_date,
                                  "show-current": _vm.batch.start_date || true,
                                  "no-title": "",
                                },
                                model: {
                                  value: _vm.batch.end_date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.batch, "end_date", $$v)
                                  },
                                  expression: "batch.end_date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-title", { staticClass: "pl-0" }, [
                    _vm._v("Filters"),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.filters,
                              label: "Filter Type",
                              "item-text": "text",
                              "item-value": "value",
                              outlined: "",
                              mulitple: "",
                              dense: "",
                              "background-color": "input",
                              "prepend-inner-icon": "mdi-filter-outline",
                              "menu-props": { offsetY: true },
                              disabled: _vm.readonly,
                            },
                            model: {
                              value: _vm.filterType,
                              callback: function ($$v) {
                                _vm.filterType = $$v
                              },
                              expression: "filterType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _vm.filterType === "promo"
                            ? _c("v-autocomplete", {
                                attrs: {
                                  "auto-select-first": "",
                                  "item-text": "promo_name",
                                  "item-value": "promo_id",
                                  items: _vm.promos,
                                  loading: _vm.loadingItems,
                                  hint: _vm.inputHint,
                                  "persistent-hint": !_vm.searchFields,
                                  "menu-props": {
                                    maxHeight: 215,
                                    maxWidth: 650,
                                  },
                                  "background-color": "input",
                                  "small-chips": "",
                                  clearable: "",
                                  dense: "",
                                  label: "Select",
                                  multiple: "",
                                  outlined: "",
                                  disabled: _vm.readonly,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-chip",
                                            _vm._b(
                                              {
                                                staticStyle: {
                                                  "max-width": "90%",
                                                },
                                                attrs: {
                                                  "input-value": data.selected,
                                                  close: "",
                                                  small: "",
                                                },
                                                on: {
                                                  click: data.select,
                                                  "click:close": function (
                                                    $event
                                                  ) {
                                                    return _vm.remove(
                                                      data.item.promo_id
                                                    )
                                                  },
                                                },
                                              },
                                              "v-chip",
                                              data.attrs,
                                              false
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-truncate",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.promo_name
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "item",
                                      fn: function (ref) {
                                        var item = ref.item
                                        return [
                                          [
                                            _c("v-checkbox", {
                                              staticClass: "mr-2",
                                            }),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c(
                                                  "v-list-item-subtitle",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [_vm._v(" Promo Name ")]
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.promo_name) +
                                                      " "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-list-item-subtitle",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [_vm._v(" Promo Number ")]
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.promo_number
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-list-item-subtitle",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [_vm._v(" Promo Dates ")]
                                                ),
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          item.start_dt ||
                                                            item.promo_start_dt
                                                        )
                                                      ) +
                                                      " ~ " +
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          item.end_dt ||
                                                            item.promo_end_dt
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  802928795
                                ),
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              })
                            : _vm._e(),
                          _vm.readonly && _vm.filterType !== "promo"
                            ? _c("v-autocomplete", {
                                attrs: {
                                  value: _vm.activeFilter.items,
                                  items: _vm.activeFilter.items,
                                  "item-value": _vm.activeFilter.value,
                                  "item-text": _vm.activeFilter.text,
                                  loading: _vm.loadingItems,
                                  "background-color": "input",
                                  label: "Filter*",
                                  required: "",
                                  outlined: "",
                                  multiple: "",
                                  disabled: _vm.readonly,
                                  dense: "",
                                },
                              })
                            : _vm._e(),
                          !_vm.readonly && _vm.filterType !== "promo"
                            ? _c("v-autocomplete", {
                                attrs: {
                                  label:
                                    _vm.isPartyFilterActive &&
                                    !_vm.isReversalOrRebill
                                      ? "Search"
                                      : "Select",
                                  "single-line": "",
                                  "auto-select-first": "",
                                  "item-text": _vm.activeFilter.text,
                                  "item-value": _vm.activeFilter.value,
                                  items: _vm.activeFilter.items,
                                  loading: _vm.loadingItems,
                                  "menu-props": {
                                    maxHeight: 215,
                                    maxWidth: 650,
                                  },
                                  "search-input": _vm.search,
                                  hint:
                                    _vm.isPartyFilterActive &&
                                    _vm.isReversalOrRebill
                                      ? _vm.inputHint
                                      : undefined,
                                  "persistent-hint":
                                    _vm.isPartyFilterActive &&
                                    _vm.isReversalOrRebill &&
                                    !_vm.batch.reversal_batch_id,
                                  "no-filter": _vm.isPartyFilterActive,
                                  "hide-no-data": "",
                                  "background-color": "input",
                                  "small-chips": "",
                                  "deletable-chips": "",
                                  clearable: "",
                                  dense: "",
                                  multiple: "",
                                  outlined: "",
                                  disabled: _vm.readonly,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    _vm.search = $event
                                  },
                                  "update:search-input": function ($event) {
                                    _vm.search = $event
                                  },
                                },
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.readonly ? _c("v-divider") : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-auto mt-1", attrs: { cols: "auto" } },
                    [
                      _vm.overlap > 0 && !_vm.readonly
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                dense: "",
                                                color: "red",
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("mdi-alert-decagram-outline")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3632529360
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Promotion Overlap: " + _vm._s(_vm.overlap)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      !_vm.readonly
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                loading: _vm.saving,
                                disabled: _vm.saving,
                              },
                              on: { click: _vm.createBatch },
                            },
                            [_vm._v(" Start ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }